.main-input {
  &--upload {
    cursor: pointer;
    &,
    .ant-upload {
      border-radius: 4px;
      background-color: $secondary-2;
    }
    width: 200px;
    height: 162px;
    &__preview-img {
      min-height: 100px;
      max-width: 200px;
      max-height: 200px;
      object-fit: cover;
    }
    .ant-upload-drag {
      border: none !important;
    }
    &__edit-btn-holder {
      top: 12px;
      right: 12px;
      .ant-form-item-control-input {
        min-height: auto !important;
      }
    }
    &__edit-btn {
      .ant-upload-drag {
        border: none !important;
        background-color: $secondary-3 !important;
        border-radius: 50%;
        width: fit-content;
      }
      .ant-upload {
        background-color: transparent;
        padding: 0 !important;
      }
    }
  }
}
