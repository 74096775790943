/* -------------------------------------------------------------------------- */
/*                                   colors                                   */
/* -------------------------------------------------------------------------- */
$primary-1: #589559;
$primary-2: #83bf84;
$primary-3: #b1d5b2;
$primary-4: #c7e7d3;
$secondary-1: #001c30;
$secondary-2: #176b87;
$secondary-3: #70a2b3;
$secondary-4: #c9d9df;
$neutral-white: #ffffff;
$neutral-black: #111111;
$gray-1: #7a7a7a;
$gray-2: #8f8f8f;
$gray-3: #cdcdcd;
$gray-4: #f5f5f5;
$error: #e72121;
$table-green: #547256;
