.new-actor {
  width: 100%;
  padding-inline: 20px;
  &__upload-container {
    width: 100%;
    height: 285px;
    margin-block: 10px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__close-image {
    position: absolute;
    top: 20px;
    right: 30px;
  }
  &__upload-content {
    width: 250px;
    height: 255px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__upload-image {
    width: 64px;
    height: 43px;
    padding: 2px;
    margin-block-end: 10px;
    text-align: center;
    border-radius: 12px;
    background-color: #ffffff;
    object-fit: cover;
  }
  &__upload-img {
    width: 100%;
    height: 100%;
  }
  &__figure {
    width: 100%;
    height: 100%;
    border: 2px dashed #c9d0d6;
    border-radius: 12px;
    overflow: hidden;
  }
  &__upload-button {
    width: 211px;
    height: 48px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    border-radius: 12px;
    background-color: #ffffff;
    // box-shadow: 0px 1px 10px gray;
    box-shadow: 0px 12px 13px #0000000a;
  }
  &__upload-title {
    color: #356ab3;
    font-size: 13px;
    font-weight: 500;
  }
  &__upload-icon {
    color: #356ab3;
    width: 1.3rem;
    height: 1.3rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  &__name {
    width: 100%;
  }
  &__save-back-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  &__buttons {
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.new-actor-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.error-upload-image {
  width: inherit;
  min-height: 1.25rem;
  color: rgba(red, 0.8);
}
// .anticon-spin {
//   display: none;
// }
.ant-upload {
  cursor: pointer;
  background-color: $secondary-1;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  color: white;
}
@include to-tablet-sm {
  .new-actor {
    &__save-back-buttons {
      justify-content: center;
    }
    &__buttons {
      width: 100%;
    }
  }
  .view-actor-buttons {
    justify-content: space-evenly;
  }
}
