.do-dont-images {
  &__table-image {
    width: 60px;
    border-radius: 4px;
    object-fit: cover;
    height: 60px;
  }
  &__add-form {
    &__preview-img {
      width: 90px;
      height: 90px;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}
