.home {
  &__title {
    font-size: 21.33px;
    font-weight: medium;
    color: #356ab3;
  }
  &__cat {
    //wd-100 mrg-ye-20
    width: 100%;
    margin-block-end: 20px;
  }
}
