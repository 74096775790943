.store-items {
  &__data-table {
    &__item-img {
      width: 80px;
      height: 60px;
    }
    &__description {
      max-width: 30ch;
    }
    &__link {
      max-width: 20ch;
      // color: $secondary-3
    }
  }
  &__item-form {
    // &__preview-image-holder {
    //   padding: 17px 12px 12px;
    // }
    &__item-preview-img {
      width: 70px;
      max-height: 110px;
    }

    &__images {
      &__images-holder {
        max-width: 300px;
        min-width: 180px;
        height: 135px;
        overflow-x: auto;
        // scrollbar-color: $primary-3 $gray-4;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
        &::-webkit-scrollbar-track {
          // background: $gray-4;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          // background: $primary-3;
        }
      }
      &__preview-img {
        width: 100px;
        object-fit: cover;
        // aspect-ratio: 1/1;
        border-radius: 8px;
        display: block;
      }
      .main-input--upload {
        display: block;
        .ant-upload {
          padding: 0 !important;
          width: 100% !important;
          height: 100px;
          // border-radius: 0;
          &-disabled {
            background-color: rgb(232 232 232);
          }
        }
        &__edit-btn {
          position: absolute;
          top: 5px;
          right: 5px;
          height: auto !important;
          border: none;
          padding: 0;
          background-color: rgba(255, 255, 255, 0.5);
          line-height: 1;
          border-radius: 2px;
          .ant-upload {
            background-color: transparent !important;
            padding: 0;
          }
        }
      }
      &__input,
      .main-input--upload {
        width: 100px !important;
        border: none;
      }
      .main-input--upload {
        height: auto;
        padding: 0 !important;
      }
      &__upload-icon {
        width: 40px;
        height: 40px;
      }
    }

    &__description {
      textarea {
        border-radius: 10px;
        padding: 8px;
        &:not(.ant-input-status-error) {
          border: 1px solid #d9d9d9 !important;
          &:hover {
            border-color: var(--ant-primary-5) !important;
          }
        }
        &.ant-input-status-error {
          border: 1px solid #ff4d4f !important;
        }
      }
    }
  }
}
