.redeem-points {
  &__address {
    max-width: 35ch;
    min-width: 15ch;
  }
  &__type-switch {
    &.ant-switch-checked {
      background-color: $primary-2 !important;
    }
  }
  &__view-details-modal {
    h2 {
      color: $secondary-1;
    }
    &__item-img {
      width: 70px;
      border-radius: 4px;
      max-height: 100px;
      object-fit: cover;
    }
  }
}
