$max-media-width-sm: 180px;
$max-media-width-lg: 240px;
.chat {
  .row {
    @include from-tablet-sm {
      max-height: calc(100vh - 114px);
    }
    & > div {
      &,
      & > * {
        @include from-tablet-sm {
          height: 100%;
        }
      }
    }
  }
  &__rooms {
    background-color: white;
    padding-block: 16px;
    border-radius: 8px;
    max-height: 100%;
    &__aside-search {
      padding-inline: 12px;
    }
    &__search-holder {
      input {
        padding-right: 19px;
      }
      &__clear-btn {
        right: 4px;
        svg {
          fill: $gray-2;
        }
      }
    }
    &__holder {
      padding-inline: 12px;
      scrollbar-width: thin;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 300px;
      @include to-tablet-sm {
        min-height: 300px;
      }
      @include from-tablet-sm {
        max-height: 100%;
      }
      li {
        padding-block: 8px;
        &:first-child:last-child,
        &:not(:last-child) {
          border-bottom: 2px solid #f1f1f1;
        }
      }
      &__room {
        cursor: pointer;
        padding: 9px;
        transition: background-color 0.3s ease;
        border-radius: 4px;
        background-color: white;
        &--with-unread-messages {
          background-color: #c7e7d32e;
          * {
            font-weight: bold;
          }
        }
        &--active {
          &,
          &:hover {
            cursor: auto;
            background-color: $primary-4;
          }
        }
        &__username {
          color: $primary-1;
          line-height: 1.25;
          font-weight: bold;
          max-width: 87%;
          @include from-tablet-sm {
            max-width: 79%;
          }
        }
        &__notifications {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: $primary-1;
          color: white;
          font-size: 0.75rem;
        }
        &__message {
          max-width: 100%;
          color: $gray-1;
          // width: 20ch;
          &__icon {
            width: 12px;
          }
        }
        &__time {
          color: $gray-2;
          font-size: 0.85rem;
        }
      }
    }
  }
  &__chat-holder {
    background-color: white;
    border-radius: 8px;
    &__header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      background-color: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: $secondary-2;
      &__user-icon {
        background-color: grey;
        width: 28px;
        border-radius: 50%;
      }
      &__search-bar {
        height: 0;
        transition: height 0.3s ease;
        &.show {
          height: 32px;
        }
      }
    }
    &__body {
      background-color: $gray-4;
      overflow-y: auto;
      scrollbar-width: thin;
      height: 340px;
      @include from-tablet-sm {
        height: 100%;
      }
      & > div {
        padding: 10px;
        gap: 12px;
      }
      &__day-date {
        background-color: $primary-2;
        color: white;
        padding: 1px 15px;
        border-radius: 12px;
        font-weight: 500;
        font-size: 0.7rem;
        width: fit-content;
        position: sticky;
        top: 10px;
        padding-block: 3px;
        margin-bottom: 10px;
        z-index: 1002;
      }
      &__message {
        &__holder {
          max-width: 100%;
          width: fit-content;
          // max-width: 90%;
          background-color: white;
          padding: 7px;
          border-radius: 9px;
          @include from-tablet-sm {
            max-width: 70%;
          }
          .ant-skeleton-image {
            width: $max-media-width-sm !important;
            @include from-tablet-sm {
              width: $max-media-width-lg !important;
            }
          }
          &--sent-by-current {
            background-color: $secondary-4;
          }
          &__message {
            white-space: pre-line;
            &[dir="rtl"] {
              padding-left: 40px;
            }
            &[dir="ltr"] {
              padding-right: 40px;
            }
          }
          &__image {
            border-radius: 5px;
            width: $max-media-width-sm !important;
            @include from-tablet-sm {
              width: $max-media-width-lg !important;
            }
            &--video-thumbnail {
              width: $max-media-width-sm !important;
              @include from-tablet-sm {
                width: $max-media-width-lg !important;
              }
              video {
                border-radius: 5px;
              }
              button {
                background-color: rgba(0, 0, 0, 0.5) !important;
                z-index: 4;
                img {
                  width: 32px;
                }
              }
            }
            &__preview-holder {
              .ant-image-preview-mask {
                z-index: 1010;
              }
              .ant-image-preview-wrap {
                z-index: 1011;
              }
              .ant-image-preview-img-wrapper {
                padding: 12px;
              }
              .ant-image-mask {
                border-radius: 5px;
              }
              video {
                max-height: 70vh;
              }
              &__icon-holder {
                border-radius: 50%;
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                &,
                &:hover,
                &:disabled {
                  background-color: rgba(0, 0, 0, 0.35) !important;
                }
                svg {
                  width: 24px;
                  height: 24px;
                  fill: white;
                }
                &:disabled {
                  svg {
                    fill: gray;
                  }
                }
              }
            }
          }
          &__document {
            padding-top: 4px;
            max-width: $max-media-width-sm !important;
            @include from-tablet-sm {
              max-width: $max-media-width-lg !important;
            }
            &__name {
              max-width: 18ch;
              @include from-tablet-sm {
                max-width: 23ch;
              }
            }
            &__icon {
              width: 28px;
            }
            &__upload-preview {
              &__confirmation-msg {
                color: $primary-1;
              }
              &__files-holder {
                &__name {
                  max-width: 30ch;
                }
                &__icon {
                  width: 24px;
                }
                &__size {
                  color: $gray-1;
                }
              }
            }
          }
          &__location {
            width: $max-media-width-sm !important;
            @include from-tablet-sm {
              width: $max-media-width-lg !important;
            }
            max-width: 100%;
          }
          &__timestamp {
            color: $gray-2;
            padding-top: 2px;
            font-size: 0.75rem;
          }
          &__audio {
            width: $max-media-width-sm !important;
            @include from-tablet-sm {
              width: $max-media-width-lg !important;
            }
            @include from-tablet-sm {
              padding: 4px;
            }
            &__holder {
              padding: 2px !important;
              box-shadow: none !important;
              background-color: $secondary-4 !important;
              .rhap_progress-container {
                margin: 0 8px 0 0;
              }
              .rhap_controls-section {
                flex: unset;
              }
              .rhap_play-pause-button {
                width: fit-content;
                height: auto;
                font-size: unset;
                transform: translateY(1px);
              }
              &__current-time {
                margin-left: 31px;
              }
              .rhap_time,
              &__current-time {
                // color: #979797;
                font-size: 0.75rem;
              }
              .rhap_progress-filled,
              .rhap_progress-bar-show-download,
              .rhap_progress-bar,
              .rhap_progress-indicator,
              .rhap_download-progress {
                background-color: transparent;
              }
              .rhap_download-progress,
              .rhap_progress-filled {
                border-radius: 0;
                border-top-width: 1px;
                border-top-style: solid;
                transform: translateY(2px);
              }
              .rhap_download-progress {
                width: 100% !important;
                left: 0 !important;
                border-top-color: $gray-4;
              }
              .rhap_progress-filled {
                border-top-color: $primary-1;
              }
              .rhap_progress-indicator {
                background-color: $primary-1;
                width: 12px;
                height: 12px;
                top: -4px;
                margin-left: -6px;
                box-shadow: none;
              }
              &--from-user {
                background-color: white !important;
                .rhap_time,
                &__current-time {
                  color: $secondary-1;
                }
                .rhap_download-progress {
                  border-top-color: $gray-2;
                }
              }
              &__mic-icon {
                width: 13px;
                transform: translateX(-5px);
                @include from-tablet-sm {
                  transform: translateX(-8px);
                  width: 15px;
                }
              }
              &__sender-pic {
                width: 28px;
                @include from-tablet-sm {
                  width: 32px;
                }
              }
            }
          }
        }
      }
    }
    &__footer {
      background-color: white;
      z-index: 1004;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 9px;
      @include from-tablet-sm {
        padding: 15px 23px;
      }
      &__upload-options {
        background-color: white;
        box-shadow: 0 0 4px 0 #00000026;
        padding: 16px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 1003;
        top: 0;
        transform: translateY(0);
        transition: transform 0.3s ease;
        gap: 24px;
        left: 9px;
        width: 81%;
        @include from-tablet-sm {
          padding: 24px;
          left: 23px;
        }
        &[data-show="true"] {
          transform: translateY(calc(-100% - 10px));
        }
        .ant-upload {
          background-color: transparent;
          padding: 0;
          border-radius: unset;
        }
        img {
          width: 30px;
          height: 30px;
          @include from-tablet-sm {
            width: 58px;
            height: 58px;
          }
        }
      }
      &__upload-preview-holder {
        &__video {
          max-height: 70vh;
        }
        .ant-image-preview-img-wrapper {
          padding-inline: 16px;
        }
        .ant-image-preview-progress {
          margin-bottom: 10px;
        }
        &__preview-upload-btn {
          font-size: 1.125rem;
          background-color: $secondary-1 !important;
          svg {
            fill: white;
          }
        }
      }
      &__upload-error-alert {
        position: absolute;
        right: 20px;
        top: 0;
        transform: translateY(calc(-100% - 10px));
        z-index: 16;
      }
      textarea {
        resize: none;
        min-height: 42px;
        height: 42px;
        padding-block: 7px;
        padding-right: 31px;
        @include from-tablet-sm {
          padding-right: 38px;
          min-height: 48px;
          height: 48px;
        }
        &,
        &:focus,
        &:hover {
          background: white;
          border: 2px solid #f6f6f6 !important;
          border-width: 2px !important;
        }
        @include from-tablet-sm {
          padding-right: 46px;
        }
        &[data-lang="ar"] {
          padding-right: 6px;
          padding-left: 38px;
          @include from-tablet-sm {
            padding-left: 46px;
          }
          &::placeholder {
            direction: rtl;
            text-align: end;
          }
        }
      }
      &__voice-record-holder {
        background-color: #1c2a57;
        padding: 16px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        @include from-tablet-sm {
          padding: 22px 31px;
        }
        @include from-tablet-sm {
          padding-left: 70px;
        }
        &__cancel-btn {
          color: $error !important;
        }
        &__send-btn {
          width: 38px;
          height: 38px;
          background-color: $primary-1 !important;
          border-radius: 50%;
          @include from-tablet-sm {
            width: 48px;
            height: 48px;
          }
        }
      }
      &__submit-btn {
        transition: filter 0.3s ease;
        &:disabled {
          filter: contrast(0.1);
        }
      }
      &__mic-btn {
        width: 20px;
        @include from-tablet-sm {
          width: 24px;
        }
      }
      &__attach-btn {
        img {
          width: 14px;
          @include from-tablet-sm {
            width: 20px;
          }
        }
      }
    }
    &__new-messages-alert {
      bottom: 89px;
      left: 50%;
      z-index: 1003;
      transform: translateX(-50%);
      background-color: white;
      color: $primary-1;
      border-radius: 25px;
      padding: 1px 12px;
      box-shadow: 0px 0px 4px 0px #176b8757;
      font-size: 0.75rem;
      padding-block: 6px;
      img {
        width: 8px;
      }
    }
  }
}
