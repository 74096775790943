/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.general-section {
  min-width: 100%;
  // min-height: -webkit-fit-content;
  // min-height: -moz-fit-content;
  // min-height: fit-content;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .side-bar {
    width: 312px;
    height: 100%;
  }

  .all-content {
    background-color: $primary-1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .nav-bar {
      width: 100%;
      -webkit-margin-after: 2rem;
      margin-block-end: 2rem;
    }
    .component-wrapper {
      width: 100%;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content;
      padding-inline: 10px;
      @include from-tablet-sm {
        padding-inline: 20px;
      }
      .component {
        width: 100%;
        height: inherit;
      }
    }
  }
}
.content-fixed-width {
  width: calc(100% - 312px);
}
.content-full-width {
  width: 100%;
}
//media query
@include to-tablet-lg {
  .general-section {
    .side-bar {
      width: 70px;
    }
    .all-content {
      width: calc(100% - 72px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
}
