.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__title {
    color: $secondary-1;
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  &__title-rtl {
    color: #1a1d1f;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.4px;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-inline-end: 20px;
  }
  &__color {
    width: 1rem;
    height: 2rem;
    margin-inline-end: 1rem;
    border-radius: 0.1875rem;
  }
  &__close {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    background-color: $gray-3;
    color: $neutral-white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }

}
