.no-network {
  padding-block: 24px;
  &__container {
    gap: 1rem;
    padding: 16px;
  }
  .illustration-image {
    width: 500px;
    @include from-desktop-sm {
      width: 600px;
    }
  }
  h1 {
    font-size: 2rem;
    color: #0f3264;
    @include from-desktop-sm {
      font-size: 3rem;
    }
  }
  p {
    font-size: 0.875rem;
    color: #011a3e;
    @include from-desktop-sm {
      font-size: 1.25rem;
      width: 420px;
    }
  }
  &__retry-btn {
    width: 282px;
    border-radius: 16px;
    padding-block: 10px;
    background-color: #60ec2f;
    color: white;
    font-size: 1rem;
    @include from-tablet-sm {
      font-size: 1.5rem;
    }
  }
}
