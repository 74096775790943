.cans-sales-requests {
  &__add-request-form {
    &__item {
      background-color: $primary-3;
      padding: 16px;
      border-radius: 8px;
      &__units-radio {
        .ant-radio-wrapper {
          span:last-child {
            color: $secondary-1;
            font-weight: 600;
          }
        }
      }
    }
    &__add-request-btn {
      background-color: $table-green !important;
    }
  }
  &__view-details {
    .ant-select:hover .ant-select-arrow:not(:last-child) {
      opacity: 1 !important;
    }
    .ant-form-item .ant-form-item-explain-error {
      text-align: start;
    }
    .ant-pagination-prev .ant-pagination-item-link:not(:disabled),
    .ant-pagination-next .ant-pagination-item-link:not(:disabled) {
      color: $secondary-1 !important;
    }
    .ant-pagination-item,
    .ant-pagination-item:hover a {
      color: $secondary-1;
    }
    .ant-pagination .ant-pagination-item-active {
      background-color: #c7e7d361;
    }
  }
}
