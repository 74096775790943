.sidebar {
  width: 312px;
  height: 100vh;
  background-color: $neutral-white;
  position: fixed;
  top: 0;
  left: 0;
  @include to-tablet-lg {
    width: 70px;
    max-width: 6.25rem !important;
  }
  &__counter {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 8px;
    background-color: #dc0e0e;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-40%, -40%);
  }
  .logo {
    max-width: 120px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include to-tablet-lg {
      display: none;
    }
  }
  .account {
    padding-inline: 8px;
    margin-block-start: 0.625rem;
    color: $primary-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: 47px;
      height: 47px;
      overflow: hidden;
      background-color: #4d5157;
      text-align: center;
      border-radius: 0.625rem;
      margin-inline-end: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex: 1;
      .admin {
        font-size: 14px;
        font-weight: medium;
      }
      .account-name {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .logout {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-3;
      padding: 8px;
      border-radius: 2px;
    }
  }
  .list {
    padding-inline: 8px;
    padding-bottom: 8px;
    overflow-y: auto;
    scrollbar-width: thin;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .category {
      width: 100%;
      padding: 8px;
      margin-block-end: 4.8px;
      color: $primary-1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border-radius: 8px;
      @include to-tablet-lg {
        justify-content: center;
      }
      .cat-icon {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline-end: 12px;
        @include to-tablet-lg {
          margin-inline-end: 0;
        }
        svg {
          width: 20px;
          height: 20px;
          fill: $primary-1;
        }
      }
      .category-name {
        font-size: 1rem;
        font-weight: 500;
        @include to-tablet-lg {
          display: none;
        }
      }
    }
    .category:hover {
      background-color: $primary-3;
    }
    .selected {
      svg {
        fill: $neutral-white !important;
      }
      color: $neutral-white !important;
      background-color: $primary-1 !important;
    }
  }
  .details {
    @include to-tablet-lg {
      display: none;
    }
  }
  .account {
    @include to-tablet-lg {
      flex-direction: column;
      padding-inline: 8px;
      padding-block: 16px;
    }
    .details {
      .admin {
        @include to-tablet-lg {
          display: none;
        }
      }
      .account-name {
        @include to-tablet-lg {
          text-align: center;
          font-size: 12.8px;
          font-weight: bold;
          margin-block: 16px;
        }
      }
    }
  }
  &__icon {
    @include to-tablet-lg {
      background-color: #3b3e4d;
      width: 32px;
      height: 32px;
      margin: 0;
    }
  }
  &__logout {
    @include to-tablet-lg {
      width: 27.8px;
      height: 27.8px;
      margin: 0;
    }
  }
}
