/*********************************************************************/
/*                              Families                             */
/*********************************************************************/

*,
body {
  line-height: normal;
}

.font--light {
  &,
  & * {
    font-weight: 300;
  }
}

.font--semi-bold {
  &,
  & * {
    font-weight: 500;
  }
}

.font--bold {
  &,
  & * {
    font-weight: 700;
  }
}

/*********************************************************************/
/*                                Sizes                              */
/*********************************************************************/
@include to-tablet-sm {
  .fsize--1 {
  }
  .fsize--2 {
  }
  .fsize--3 {
    font-size: 1.125rem !important;
  }
  .fsize--4 {
    font-size: 0.875rem !important;
  }
  .fsize--5 {
    font-size: 1rem !important;
  }
  .fsize--6 {
  }
  .fsize--7 {
    font-size: 0.875rem !important;
  }
  .fsize--8 {
    font-size: 0.875rem !important;
  }
  .fsize--9 {
    font-size: 0.875rem !important;
  }
  .fsize--10 {
    font-size: 0.875rem !important;
  }
  .fsize--11 {
    font-size: 0.6875rem !important;
  }
  .fsize--12 {
  }
  .fsize--13 {
  }
}

@include from-tablet-sm {
  .fsize--1 {
    font-size: 3.125rem !important;
  }
  .fsize--2 {
    font-size: 3rem !important;
  }
  .fsize--3 {
    font-size: 2rem !important;
  }
  .fsize--4 {
    font-size: 1.75rem !important;
  }
  .fsize--5 {
    font-size: 1.5rem !important;
  }
  .fsize--6 {
    font-size: 1.375rem !important;
  }
  .fsize--7 {
    font-size: 1.25rem !important;
  }
  .fsize--8 {
    font-size: 1.125rem !important;
  }
  .fsize--9 {
    font-size: 1rem !important;
  }
  .fsize--10 {
    font-size: 0.875rem !important;
  }
  .fsize--11 {
    font-size: 0.75rem !important;
  }
  .fsize--12 {
    font-size: 0.625rem !important;
  }
  .fsize--13 {
    font-size: 0.5rem !important;
  }
}

@include from-desktop-sm {
  .fsize--1 {
    font-size: 3.125rem !important;
  }
  .fsize--2 {
    font-size: 3rem !important;
  }
  .fsize--3 {
    font-size: 2rem !important;
  }
  .fsize--4 {
    font-size: 1.75rem !important;
  }
  .fsize--5 {
    font-size: 1.5rem !important;
  }
  .fsize--6 {
    font-size: 1.375rem !important;
  }
  .fsize--7 {
    font-size: 1.25rem !important;
  }
  .fsize--8 {
    font-size: 1.125rem !important;
  }
  .fsize--9 {
    font-size: 1rem !important;
  }
  .fsize--10 {
    font-size: 0.875rem !important;
  }
  .fsize--11 {
    font-size: 0.75rem !important;
  }
  .fsize--12 {
    font-size: 0.625rem !important;
  }
  .fsize--13 {
    font-size: 0.5rem !important;
  }
}
