.users {
  width: 100%;
  height: 100%;
  &__all-contents {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__usersData {
    width: 100%;
    height: 100%;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .admin-status-switch {
      background-color: $primary-1 !important;
      min-width: 35px;
    }
  }
  &__table {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .ant-table-wrapper,
    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }
    .ant-spin-container {
      display: flex;
      flex-direction: column;
      .ant-table {
        // flex: 1 1 auto;
        background-color: transparent;
      }
    }
  }
}

.dealers {
  &__balance-details-modal {
    &__data-toggler {
      .ant-radio-button-wrapper {
        border-color: $primary-1 !important;
        &:focus-within {
          box-shadow: none !important;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &,
        &:hover {
          color: $primary-1;
        }
        &::before {
          content: none;
        }
        &-checked:not(.ant-radio-button-wrapper-disabled) {
          background: $primary-1 !important;
          border-color: $primary-1 !important;
          &:focus-within {
            box-shadow: none !important;
          }
        }
      }
    }
    &__holder {
      max-height: 49vh;
      overflow-y: auto;
      padding: 16px;
    }
    &__check-holder {
      background-color: $gray-4;
      border-radius: 6px;
      h3 {
        color: $primary-1;
        font-weight: 700;
      }
      &__label {
        font-weight: 700;
        color: $secondary-1;
      }
      &__value {
        font-weight: 700;
        color: $gray-1;
      }
    }
    &__total-details {
      &__label {
        color: $secondary-1;
        font-weight: 500;
      }
      &__value {
        background-color: $primary-4;
        display: inline-block;
        border-radius: 4px;
        padding: 4px 8px;
        color: $primary-1 !important;
        font-weight: 700;
        &--link {
          max-width: 35ch;
        }
      }
    }
    &__empty-msg {
      color: $gray-1;
      font-size: 1.25rem;
    }
  }
}
