.sign-in {
  height: 100vh !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-1 !important;
  .title {
    font-size: 25px;
    font-weight: bold;
    background-color: $table-green;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .form {
    border: solid red 1px;
    width: 32.4375rem;
    margin-inline: auto;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .form-item {
    padding-block: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    color: $primary-2 !important;
    background-color: $gray-4 !important;
    border-radius: 25px;
    box-shadow: none !important;
  }

  input {
    background-color: $gray-4 !important;
    font-weight: bold;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:focus {
    box-shadow: none !important;
  }

  // .ant-input:not(.ant-input-affix-wrapper-status-error):focus {
  //   border: 0 !important;
  //   border-color: transparent !important;
  // }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    box-shadow: none !important;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:hover {
    background-color: $gray-4 !important;
  }

  input:autofill,
  input:autofill:focus,
  .form-item:autofill,
  .form-item:autofill:focus {
    -webkit-text-fill-color: $primary-1;
    -webkit-box-shadow: 0 0 0 30px $gray-4 inset !important;
    -moz-box-shadow: 0 0 0 30px $gray-4 inset !important;
    -o-box-shadow: 0 0 0 30px $gray-4 inset !important;
    box-shadow: 0 0 0 30px $gray-4 inset !important;
  }

  .btn-login {
    width: 100%;
    height: 40px;
    border-radius: 1.5625rem;
    color: white;
    font-size: 1.0938rem;
    font-weight: 500;

    background: $table-green !important;
    &.ant-btn:hover,
    &.ant-btn:focus {
      border: none;
      color: white;
    }
  }
  .ant-input-password-icon.anticon {
    color: $primary-1;
    // color: $secondary-2;
  }
}
