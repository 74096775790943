.main-date-picker {
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .ant-picker-clear {
    opacity: 1 !important;
  }

  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }

  @include to-mobile-sm {
    .range-picker-holder {
      width: 200px;
    }
  }
}
