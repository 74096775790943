.new-category {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: start;
  &__form-item {
    width: 100%;
  }
  .ant-form-item-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ant-form-item-control-input {
    width: 100%;
  }
  .ant-select-selector {
    background: #f4f4f4 !important;
    // border: none !important;
    border-radius: 10px !important;
    text-align: start;
  }
  .ant-select-selector::selection {
    border: none !important;
  }
}
