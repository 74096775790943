.couriers {
  &__form {
    &__edit-password-btn {
      img {
        width: 30px;
      }
    }
  }
  &__logs-modal {
    &__holder {
      max-height: 60vh;
      overflow-y: auto;
    }
  }
}
