/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  word-wrap: break-word;
}
ul,
li {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
.assign-user-to-sales-person {
  border: solid gray 1px;
  min-width: 150px;
}
.text--secondary-1 {
  color: $secondary-1;
}
.text--secondary-2 {
  color: $secondary-2;
}
.text--primary-1 {
  color: $primary-1;
}
#root {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input[dir="rtl"],
textarea[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

input[dir="rtl"]::placeholder,
textarea[dir="rtl"]::placeholder {
  direction: ltr;
  text-align: left;
}

.sr-only {
  display: none;
}

.text--max-20ch {
  max-width: 20ch;
}

.action-btns-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
/* -------------------------------------------------------------------------- */
/*                                   blocks                                   */
/* -------------------------------------------------------------------------- */
section.main-section {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.custom-column {
  width: 150px !important; /* Adjust the width as needed */
}
.h-100vh {
  height: 100vh;
  min-height: 28.125rem;
}
.ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100%;
}
/* -------------------------------------------------------------------------- */
/*                                   buttons                                  */
/* -------------------------------------------------------------------------- */
// used with antd Button
.main-btn-green {
  background-color: green;
  color: white;
  border: 1px solid green;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease;
  -o-transition: color 0.3s ease, background-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.main-btn-green:hover {
  background-color: white;
  color: green;
}
.new-plus-button,
.new-plus-button:hover,
.new-plus-button:focus {
  width: 100%;
  border-radius: 12px;
  border: solid 1px #356ab3;
  padding-block: 1.2rem;
  padding-inline: 1rem;
  overflow: hidden;
  font-size: 15px;
  font-weight: medium;
  text-align: center !important;
  color: #356ab3;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .anticon-plus {
    font-size: 16px;
  }
}

%buttons-popups {
  width: 129px;
  height: 40px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: medium;
  text-align: center !important;
}
.primary-popup-button,
.primary-popup-button:hover,
.primary-popup-button:focus {
  @extend%buttons-popups;
  background: $primary-1 !important;
  color: white !important;
  border: none !important;
}
.secondary-popup-button,
.secondary-popup-button:hover,
.secondary-popup-button :focus {
  @extend%buttons-popups;
  background: white;
  color: $error !important;
  border: solid 2px $error !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .anticon-plus {
    font-size: 16px;
  }
}

.plus-button {
  @extend.primary-popup-button;
  width: 170px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .anticon-plus {
    font-size: 16px;
  }
}
.plus-button-100vw {
  @extend.primary-popup-button;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .anticon-plus {
    font-size: 16px;
  }
}
.secondary-plus-button {
  @extend.secondary-popup-button;
  width: 170px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .anticon-plus {
    font-size: 16px;
  }
}
@include to-mobile-sm {
  .plus-button {
    @extend.primary-popup-button;
    width: 130px !important;
  }
}
.upload-button {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  font-size: 13px;
  font-weight: 500;
  color: #356ab3;
  border-color: #efefef;
  background-color: #ffffff;
  .anticon-upload {
    font-size: 16px;
    font-weight: 500;
  }
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #356ab3 !important;
  background: #ffffff !important;
}

.new-plus-button-icon {
  font-size: 18px;
  font-weight: 700;
  color: #356ab3;
}
.new-plus-button-tv {
  width: 100%;
  color: #ffffff;
  background-color: #356ab3;
  border: solid 1px #356ab3;
  font-size: 15px;
  border-radius: 12px;
  padding-block: 1.2rem;
  padding-inline: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.new-plus-button-icon-tv {
  font-size: 18px;
  font-weight: 700;
}
.new-plus-button-icon-tv :hover {
  font-size: 18px;
  font-weight: 700;
}
.new-plus-button-tv:hover {
  color: #ffffff;
  background-color: #356ab3;
  border: solid 1px #356ab3;
}

.delete-video-button,
.delete-video-button:hover,
.delete-video-button:focus {
  width: 100%;
  color: #ed2024;
  background-color: #ffffff;
  border: solid 1px #ed2024;
  font-size: 15px;
  border-radius: 12px;
  padding-block: 1.2rem;
  padding-inline: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.episode-titles-blue {
  font-size: 25px;
  font-weight: medium;
  color: #356ab3;
}

.btn {
  &--secondary {
    color: $primary-1 !important;
    &:not(:disabled) {
      border: 1px solid $primary-1 !important;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                   modals                                   */
/* -------------------------------------------------------------------------- */
.custom-modal {
  width: 50vw;
  padding: 3.125rem;
  .modal-title {
    font-size: 1.5625rem;
    color: #000000;
    letter-spacing: -0.5px;
    text-align: center;
  }
}
@include to-tablet-sm {
  .custom-modal {
    width: 90vw;
  }
}

.ant-modal-content {
  border-radius: 14px;
  .ant-modal-header {
    border-radius: 14px 14px 0 0;
  }
}

/* -------------------------------------------------------------------------- */
/*                                    tables                                   */
/* -------------------------------------------------------------------------- */
.general-table {
  width: 80vw;
}
// .ant-table-content {
//   min-height: 60vh;
// }
.grid-table {
  width: 100%;
  margin-inline: auto;
  overflow: auto;
  th {
    text-align: center;
  }
  .ant-table-cell::before {
    height: 0 !important;
  }
  &--sales-requests {
    thead {
      th:first-child {
        .ant-table-filter-column {
          width: 92px;
        }
      }
    }
  }
}
.background-even-records {
  &,
  &:hover {
    &,
    td:not(.ant-picker-cell) {
      background-color: $table-green !important;
    }
  }
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: unset;
}
.ant-pagination-item-ellipsis {
  &,
  &:hover {
    color: $neutral-white !important;
  }
}
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 1 !important;
}
.ant-pagination-item {
  border: none !important;
  font-size: 1rem;
  &,
  &:hover a {
    color: $neutral-white;
  }
  background-color: rgba(255, 255, 255, 0.3);
  text-align: center;
  border-radius: 10px;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  color: $neutral-white;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: $neutral-white;
  &:not(:disabled) {
    color: $neutral-white !important;
  }
  background-color: transparent;
}
.ant-pagination-next .ant-pagination-item-link {
  border: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
}
.ant-pagination-item-active {
  background-color: $neutral-white;
  &,
  &:hover a {
    color: $primary-1 !important;
  }
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: $gray-1;
}
.ant-table-thead > tr > th {
  color: $primary-1 !important;
  text-align: center !important;
  font-weight: 600;
}

.ant-table-tbody > tr > td {
  color: white;
  text-align: center;
}

.ant-table-tbody > tr:not(.background-even-records) > td {
  color: $table-green !important;
  background-color: white;
}

.ant-table-filter-trigger {
  img {
    width: 14px;
  }
}

.export-btn {
  box-shadow: 0 0 0px 2px rgba(0, 0, 0, 0.04);
  &,
  &:hover {
    background-color: $neutral-white;
    color: $primary-1;
    border-radius: 4px;
  }
}

.ant-modal {
  .ant-pagination {
    .ant-pagination-item {
      color: $primary-1;
      &-active {
        background-color: rgba(199, 231, 211, 0.3803921569);
      }
    }
    .ant-pagination-prev .ant-pagination-item-link:not(:disabled),
    .ant-pagination-next .ant-pagination-item-link:not(:disabled) {
      color: $primary-1 !important;
    }
  }
}

.ant-switch-checked {
  background-color: $primary-1;
}

// @media (max-width: 992px) {
// .ant-picker-panel:last-child .ant-picker-date-panel {
//   display: none !important;
// }

// .ant-picker-clear {
//   opacity: 1 !important;
// }

// .ant-picker-panel:first-child
//   .ant-picker-date-panel
//   .ant-picker-header-next-btn,
// .ant-picker-panel:first-child
//   .ant-picker-date-panel
//   .ant-picker-header-super-next-btn {
//   visibility: initial !important;
// }

// .ant-picker-panel.ant-picker-range-wrapper {
//   min-width: 288px !important;
// }

// .ant-picker-presets {
//   display: none !important;
// }
// }

// @include to-mobile-sm {
//   .range-picker-holder {
//     width: 200px;
//   }
// }

// .ant-picker-footer {
//   display: none !important;
// }

.ant-btn-default:disabled,
.ant-btn-default.ant-btn-disabled {
  background-color: $gray-3 !important;
  color: white !important;
}

.ant-radio-wrapper {
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $table-green !important;
      background-color: $table-green !important;
    }
  }
}

