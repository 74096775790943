.search {
  width: 100%;
  &__form-input {
    width: 100%;
    height: 100%;
  }
  &__searchField {
    border-radius: 6px;
    // background: #f4f4f4!important;
    // border: none;
    .ant-input {
      // background: #f4f4f4;
    }
  }
  input {
    // background: #f4f4f4;
    border: none;
  }
  &__button {
    outline: none;
    border: none;
    // background: #f4f4f4 !important;
  }
}
.ant-select-selector {
  // border: none !important;
  // background: #f4f4f4 !important;
}
.ant-input-prefix {
  margin-inline-end: 0.9375rem;
}
.select-secondary-style {
  width: 110px;
  border: solid #356ab3 1px;
  border-radius: 10px;
  overflow: hidden;
  padding-block: 2px;
  .ant-select-selector {
    // background: #fff !important;
  }
}

@include to-tablet-sm {
  .search {
    flex: 1;
  }
}
