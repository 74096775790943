.sales-requests {
  &__receipt-img {
    width: 80px !important;
    border-radius: 4px;
    min-height: 30px;
    height: 60px!important;
    object-fit: cover;
  }
  &__receipt-video {
    width: 90%;
    max-height: 60vh;
    @include from-tablet-sm {
      width: 70%;
    }
    @include from-tablet-lg {
      width: 60%;
    }
    &__thumbnail {
      border-radius: 4px;
    }
  }
}
