.social-orders {
  &__view-details {
    &__images-preview {
      .ant-image-preview-img-wrapper {
        padding: 16px;
      }
      .ant-image-preview-img {
        max-height: 60vh;
      }
      .ant-image-preview-mask {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
    &__address-textarea {
      width: 200px;
    }
    &__text-input {
      width: 120px !important;
    }
    &__number-input {
      input {
        width: 120px !important;
      }
    }
  }
  .ant-form-item-explain-error {
    text-align: start;
  }
  &__note-input {
    width: 200px;
    textarea {
      resize: none;
    }
  }
  &__date-picker-holder {
    width: 180px;
  }
}
