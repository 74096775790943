.header {
  width: 100%;
  padding-inline-end: 1.5rem;
  margin-block: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &__title {
    color: $secondary-2 !important;
    font-size: 2.5rem;
    letter-spacing: -0.8px;
    flex: 1;
  }
  &__search {
    max-width: 17.75rem;
  }
  &__plus-button {
    .add-item-button {
      @include from-tablet-sm {
        width: 220px;
      }
      @include from-desktop-sm {
        width: 260px;
      }
      max-width: 100%;
      border-radius: 16px;
      padding: 20px 16px;
      background-color: $primary-1;
      font-weight: bold;
      color: $neutral-white;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
